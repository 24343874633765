<template>
  <div>
    <div class="section-title section-title_app">
      <h3>RESULTADOS DEL DIA</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <div class="column is-2">
            <b-field label="Fecha inicio"
                     label-position="on-border">
              <b-datepicker placeholder="2023" v-model="date_order" @input="() => this.getData()"
                            :max-date="maxDatetime"
                            nearby-selectable-month-days
                            :min-date="minDatetime"/>
            </b-field>
          </div>

          <div class="column is-2">
            <b-field label="Fecha final"
                    label-position="on-border">
              <b-datepicker placeholder="2023" v-model="date_order_end" @input="() => this.getData()"
                            :max-date="maxDatetime"
                            nearby-selectable-month-days
                            :min-date="minDatetime"/>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Orden"
                     label-position="on-border">
              <b-input v-model="order_no" @input="() => this.getData()"/>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Estudio"
                     label-position="on-border">
              <b-autocomplete
                  v-model="study"
                  placeholder="Radiografía de Torax"
                  @input="handleInputStudy"
                  :loading="searchingStudy"
                  :data="studies"
                  :clearable="true"
                  :clear-on-select="false"
                  @select="handleSelectStudy"
                  class="lapi-monitor-autocomplete"
              >
                <template slot-scope="props">
                  <span class="lapi-monitor-autocomplete__option">{{props.option.name}}</span>
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Id de Muestra"
                     label-position="on-border">
              <b-input v-model="sample_no" @input="() => this.getData()"/>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Sucursal"
                     label-position="on-border">
              <b-select v-model="branch_office" @input="() => this.getData()">
                <option value="">Todas</option>
                <option :value="branchOffice.id" :key="branchOffice.id"
                        v-for="branchOffice in branchOffices">{{ branchOffice.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Modalidad de imágen"
                     label-position="on-border">
              <b-select v-model="study_clasification" @input="() => this.getData()">
                <option value=""></option>
                <option :value="studyClasification.code" :key="studyClasification.id"
                      v-for="studyClasification in studyClasification">{{studyClasification.name}}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Estado"
                     label-position="on-border">
              <b-select v-model="status" @input="() => this.getData()">
                <option value=""></option>
                <option value="onTime">En tiempo</option>
                <option value="late">Atrasada</option>
                <option value="complete">Completada</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Ver por página"
                     label-position="on-border">
              <b-select v-model="perPage" @input="() => this.getData()">
                <option value="25">25 por página</option>
                <option value="50">50 por página</option>
                <option value="100">100 por página</option>
                <option value="500">500 por página</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-1">
            <b-tooltip label="Exportar Excel">
              <b-button size="is-small" type="is-primary" @click="handleExport">Exportar Excel</b-button>
            </b-tooltip>
          </div>
          <div class="column is-1">
            <b-field>
              <b-checkbox v-model="export_group">Agrupado</b-checkbox>
            </b-field>
          </div>
          <div class="column is-6">
            <select-company
                v-model="company"
                @select="handleSelectCompany"
                type="search"
                label-position="on-border"
            />
          </div>
          <div class="column is-3">
            <span><b>Total de encontrados: {{total}}</b></span>
          </div>
        </div>
      </div>

      <div class="column is-12 has-background-white">
        <section>
          <b-table
              class="is-size-7"
              :data="data"
              :loading="loading"
              @select=handleSelectRow
              paginated
              :per-page="perPage"
              :current-page="currentPage"
              bordered
              narrowed
              backend-pagination
              @page-change="getData"
              :total="total"
              sticky-header
              :row-class="rowClass"
              height="100%">
            <b-table-column field="order" label="Orden" v-slot="props">
              {{ props.row.order }}
            </b-table-column>
            <b-table-column field="patientCode" label="Clave de Paciente" v-slot="props">
              {{ props.row.patientCode }}
            </b-table-column>
            <b-table-column field="patientName" label="Paciente" v-slot="props">
              {{ props.row.patientName }}
            </b-table-column>
            <b-table-column field="branchOfficeName" label="Sucursal" v-slot="props">
              {{ props.row.branchOfficeName }}
            </b-table-column>
            <b-table-column field="companyName" label="Empresa" v-slot="props">
              {{ props.row.companyName }}
            </b-table-column>
            <b-table-column field="codeStudy" label="Clave de Estudio" v-slot="props">
              {{ props.row.codeStudy }}
            </b-table-column>
            <b-table-column field="studyDescription" label="Estudio" v-slot="props">
              {{ props.row.studyDescription }}
            </b-table-column>
            <b-table-column field="nameSubStudy" label="SubEstudio" v-slot="props">
              {{ props.row.nameSubStudy }}
            </b-table-column>
            <b-table-column field="typeStudy" label="Tipo de Estudio" v-slot="props">
              {{ props.row.typeStudy }}
            </b-table-column>
            <b-table-column field="date" label="Fecha" v-slot="props">
              {{ props.row.date }}
            </b-table-column>
            <b-table-column field="estimatedDate" label="Entrega Estimada" v-slot="props">
              {{ props.row.estimatedDate }}
            </b-table-column>
            <b-table-column field="statusFormatted" label="Estado" v-slot="props">
              {{ props.row.statusFormatted }}
            </b-table-column>
            <b-table-column field="idSample" label="Id de la muestra" v-slot="props">
              {{ props.row.idSample }}
            </b-table-column>
            <b-table-column field="medicalNameSign" label="Firmado por" v-slot="props">
              Clave: {{ props.row.sign }}<br>Nombre: {{ props.row.medicalNameSign }}
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                        icon="box-open"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>No hay resultados para mostrar.</p>
                </div>
              </section>
            </template>
          </b-table>
        </section>
      </div>
    </div>

    <b-modal v-model="showDetails">
      <details-task-monitor-xray :data="selectedRow"/>
    </b-modal>
  </div>
</template>

<script>
import DetailsTaskMonitorXray from "@/components/report/DetailsTaskMonitorXray";
import SelectCompany from "@/components/company/SelectCompany";
export default {
  name: "TaskMonitorXray",
  components: {
    DetailsTaskMonitorXray,
    SelectCompany,
  },
  props: {},
  data() {
    const maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() + 1, 0, 0)
    const minYear = new Date()
    minYear.setFullYear(minYear.getFullYear() - 3, 12, 1)
    return {
      showDetails: false,
      perPage: 25,
      currentPage: 1,
      total: 0,
      data: [],
      date: new Date(),
      date_order: new Date(),
      date_order_end: new Date(),
      studyClasification: [],
      study_clasification: '',
      order: '',
      status:'',
      study: '',
      sample_no: '',
      branch_office: '',
      order_no: '',
      export_group: '',
      company: '',
      maxDatetime: maxYear,
      minDatetime: minYear,
      loading: true,
      searchingStudy: false,
      studies: [],
      selectedStudy: '',
      selectedRow: {}
    }
  },
  computed: {
    branchOffices() {
      return this.$store.state.main.accountBranchOffices;
    },
    transformDate(){
      const new_date= `${this.date_order.getFullYear()}-${(this.date_order.getMonth()+1).toString().padStart(2, '0')}-${this.date_order.getDate().toString().padStart(2, '0')}`
      return new_date;
    },
    transformDate2(){
      const new_date= `${this.date_order_end.getFullYear()}-${(this.date_order_end.getMonth()+1).toString().padStart(2, '0')}-${this.date_order_end.getDate().toString().padStart(2, '0')}`
      return new_date;
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    this.getData()
    this.getStudyClasification()
  },
  methods: {
    getData(page = 1) {

      if(!this.checkDates()){
        return ;
      }

      this.loading = true;

      const study = this.selectedStudy ? this.selectedStudy.name : '';
      const params = {
        perPage: this.perPage, page, order_no:
        this.order_no, branch_office: this.branch_office,
        study: study,
        date: this.transformDate,
        date_order_end: this.transformDate2,
        sample_no: this.sample_no,
        study_clasification: this.study_clasification,
        status: this.status,
        company: this.company,
      }
      this.$http.get('/monitor/xray', {params}).then(async ({data}) => {
        this.data = data.data
        this.total = data.meta.total;
        this.currentPage = page;
      }).finally(() => {
        this.loading = false;
      })
    },
    getStudyClasification() {
      this.$http.get('/studyClasification?xray=1').then(async ({data}) => {
        this.studyClasification = data.data
      })
    },
    rowClass(item) {
      return "lapi-monitor-" + item.rowColor;
    },
    handleSelectStudy(val) {
      if(val){
        this.selectedStudy = val;
        setTimeout(() => {
          this.study = val.name;
        }, 500);
      }else{
        this.selectedStudy = null;
      }

      this.getData();
    },
    handleInputStudy( val) {
      if(typeof val === 'undefined') return ;
      this.searchingStudy = true;
      const params = {q: this.study};
      this.$http.get('/studies?xray=1', {params}).then(({data}) => {
        this.studies = data.data;
      }).finally(() => {
        this.searchingStudy = false;
      })
    },
    handleExport(){
      if(!this.checkDates()){
        return ;
      }
      this.$loader.show();
      const study = this.selectedStudy ? this.selectedStudy.name : '';
      const params = {
        order_no: this.order_no,
        branch_office: this.branch_office,
        study,
        study_clasification: this.study_clasification,
        date: this.transformDate,
        date_order_end: this.transformDate2,
        sample_no: this.sample_no,
        status: this.status,
        export_group: this.export_group,
        company: this.company,
      }
      this.$http.get('/monitor/xray/export', {params, responseType: 'blob'}).then(response => {
        const date = this.$moment().format('Y-MM-DD');
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: response.data.type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `monitor xray ${date} .xlsx`);
        document.body.appendChild(link);
        link.click();
      }).finally(() => {
        this.$loader.hide();
      })
    },
   // selectedRow(event) {
    //  console.log(event);
   //   this.showDetails=true;
  //  },
    handleSelectCompany(event){
      this.company= event.name;
      this.getData();
    },
    handleSelectRow(event) {
      console.log(event);
      this.showDetails=true;
      this.loading = true;
      const params = {
        orderNew: event.orderNew,
        codeStudy: event.codeStudy,
        codeSubStudy: event.codeSubStudy,
      }
      console.log(event.orderNew);
      this.$http.get(`/monitor/xray/details/${event.orderNew}/${event.codeStudy}/${event.codeSubStudy}`).then(async ({data}) => {
        this.selectedRow = data.data;
      }).finally(()=> {
        this.loading = false;
      })
    },
    checkDates() {
      let diff = Math.abs(this.date_order.getTime() - this.date_order_end.getTime());
      diff = diff / (1000*60*60*24);
      if(diff > 31){
        this.$buefy.dialog.alert({
          title: "Rango de fechas incorrecto",
          message: "No se puede seleccionar mas de un mes",
          canCancel: false
        });
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped>

</style>
