<template>
<div class="modal-card" style="width: auto">
  <header class="modal-card-head">
    <p class="modal-card-title">Detalle de orden</p>
    <button
        type="button"
        class="delete"
        @click="$parent.close()"/>
  </header>
  <section class="modal-card-body">
    <p><b>Orden: </b><span>{{data.orderNo}}</span></p>
    <p><b>Paciente: </b><span>{{data.patientName}}</span></p>
    <p><b>Sucursal: </b><span>{{data.branchOfficeName}}</span></p>
    <p><b>Estudio: </b><span>{{data.studyDescription}}</span></p>
    <p><b>Firmado por: </b><span>{{data.sign}}</span></p>
    <p><b>Fecha de interpretación: </b><span>{{data.sampleDate}}</span></p>
    <p><b>Fecha de orden: </b><span>{{data.date}}</span></p>
    <p><b>Fecha de entrega de la orden: </b><span>{{data.deliveryDate}}</span></p>
    <p v-if="data.image_path"><b>Enlace de imagenes EVA: </b><a :href="data.image_path" target="_blank">Ver imágenes</a></p>
  </section>
  <footer class="modal-card-foot">
    <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cerrar</b-button>
    <b-button v-if="data.sign" native-type="button" type="is-default"  @click="regeneateDocument">Volver Generar PDF</b-button>
  </footer>
</div>
</template>

<script>
import TaskMonitorXray from "@/components/report/TaskMonitorXray";
export default {
  name: "DetailsTaskMonitorXray",
  components: {
    TaskMonitorXray
  },
  props: {
    data: Object,
  },
  methods: {
    regeneateDocument(){
      this.$loader.show();
      this.$http.get(this.data.action).then(() => {
        this.$toast.success('Se genero del documento PDF con éxito');
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>
